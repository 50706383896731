import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Ocular() {
  const title = "Ocular";
  const subTitle = "眼科";
  const overview = [
    "近年眼科クリニックの開業が増えています",
    <br />,
    "一般眼科診療のほか、白内障等の日帰り手術、コンタクトレンズ診療、近視矯正手術",
    <br />,
    "などといった専門診療があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　2,000〜4,500万円〜",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド",
    <br />,
    "顕微鏡、眼圧計、視野計、眼底検査機器、視力検査機器 など",
  ];
  const point = "ポイント";
  const pointText = [
    "・治療範囲の設定",
    <br />,
    "・バリアフリーを考慮",
    <br />,
    "・メガネ店との連携",
    <br />,
    <br />,
    "手術用機器を揃える際には、手術を行わない場合に比べて2,000～3,000万円程度、機器のコストが上がるため",
    <br />,
    "白内障手術を行うか否か決定しておくことが重要です",
    <br />,
    "眼科を訪れる患者様は眼が不自由なだけではなく、高齢者や車椅子の患者様も多いためバリアフリーは必須です",
    <br />,
    "視力検査と眼鏡・コンタクトレンズの処方はメガネ店でも行えますが、",
    <br />,
    "より正確で安心感のある検査・処方を求めて、眼科を訪れるため眼鏡店との連携が重要です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
